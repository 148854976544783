









































































import { Vue, Component, Prop, VModel, Emit } from 'vue-property-decorator';
import { sortBy } from 'lodash';
import DatePickerField from '@/components/date-picker-field.vue';
import { IDateRangeConfig } from '@/interfaces/date-range-config.interface';

@Component({
  components: {
    DatePickerField,
  },
})
export default class DateRangeFilter extends Vue {
  panel = -1;

  @VModel({ required: true, default: [undefined, undefined] })
  range!: Date[];

  @Prop()
  predefinedRanges!: IDateRangeConfig[];

  @Prop()
  label!: string;

  @Prop({ default: '280px' })
  minWidth!: string;

  @Prop({ default: '280px' })
  width!: string;

  @Prop()
  prependInnerIcon!: string;

  @Prop()
  dense!: boolean;

  @Emit('change')
  onStartChange(value: Date): Date[] {
    const newRange = DateRangeFilter.orderRange([value, this.range[1]]);
    this.range = newRange;
    return newRange;
  }

  @Emit('change')
  onEndChange(value: Date): Date[] {
    const newRange = DateRangeFilter.orderRange([this.range[0], value]);
    this.range = newRange;
    return newRange;
  }

  @Emit('change')
  onPredefinedRangeClick(range: IDateRangeConfig): Date[] {
    const newRange = DateRangeFilter.orderRange([range.start, range.end]);
    this.range = newRange;
    this.panel = -1;
    return newRange;
  }

  get showPredefinedRanges(): boolean {
    return this.predefinedRanges && !!this.predefinedRanges.length;
  }

  get formattedPeriod(): string {
    let text = '';

    if (this.range[0]) {
      text += !this.range[1] ? 'A partir de ' : '';
      text += `${this.$d(this.range[0], 'short')} `;
    }

    if (this.range[1]) {
      text += `até ${this.$d(this.range[1], 'short')}`;
    }

    return text;
  }

  get dateRanges(): (IDateRangeConfig & { disabled: boolean })[] {
    return this.predefinedRanges.map((item) => ({
      ...item,
      disabled: this.range[0] === item.start && this.range[1] === item.end,
    }));
  }

  static orderRange(range: Date[]): Date[] {
    if (range[0] && range[1]) {
      return sortBy(range);
    }

    return range;
  }
}
